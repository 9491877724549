//import { Avatar } from "antd";
import { WithDefaultLayout } from "../components/DefautLayout";
//import { Title } from "../components/Title";
import { Page } from "../types/Page";
import { GetUsersResponse } from '@/functions/swagger/AlumniBackEnd';
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import useSwr from 'swr';
import { Authorize } from "@/components/Authorize";
import { useSession, signIn, signOut } from "next-auth/react";
import {
  faSignOut,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nProgress from "nprogress";
import Profile from "@/components/Profile";
import Link from 'next/link';
import { useState } from 'react';
import SearchBar from '@/components/SearchBar';

const Users: React.FC<{
  user: GetUsersResponse | undefined,
}> = ({ user }) => {
    const photoUrl = "/image/EmptyPhoto.png";
    return (
      <Link href={`/user/edit/${user?.id}`} className="block py-3 mb-1">
        <Profile photoUrl={photoUrl} name={user?.name} />
      </Link>
    );
};

const InnerIndexPage: React.FC = () => {
  const containerStyle = {
    backgroundColor: "#F0F5FB",
  };

  const [search, setSearch] = useState();
  const swrFetcher = useSwrFetcherWithAccessToken();
  const { data, mutate } = useSwr<GetUsersResponse[]>(`/api/be/api/v1/user/get-all-users?search=${search}`, swrFetcher);
  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
    mutate();
  };
  const { status } = useSession();

  const handleSignOut = () => {
    nProgress.start();
    signOut({
      callbackUrl: '/api/end-session'
    });
  }

  const handleSignIn = () => {
    nProgress.start();
    signIn("oidc");
}

    return (
        <div style={containerStyle}>
          <div className="flex flex-auto justify-between">
              <h1 className="text-xl font-bold mb-4">MANAGE USER</h1>
              <div className="justify-end ">
                {/* ... /}
                {/ Tambahkan tombol Sign In dan Sign Out */}
                {status === "authenticated" ? (
                    <button className="text-black px-4 py-1 rounded-md " onClick={handleSignOut}>
                    <FontAwesomeIcon icon={faSignOut} />
                    </button>
                    ) : (
                    <button className="text-black px-4 py-1 rounded-md" onClick={handleSignIn}>
                    <FontAwesomeIcon icon={faSignIn} />
                    </button>
                )}
              </div>
          </div>
      {/* Add this line */}
      {/*parent */}
      <div className="flex">
        {/* child left */}
        <div className="w-1/3 rounded-md border bg-white overflow-y-auto h-screen">
          <div className="flex-col ">
            <div className="h-full mx-2 my-2 bg-white self-start">
              {/* <!-- Konten elemen kiri --> */}
              <div className="bg-white rounded-md p-4 mb-4">
                <SearchBar onSearch={handleSearch}/>
              </div>
              {data?.map((x, i) => <Users key={i} user={x}></Users>)}
            </div>
          </div>
        </div>
        {/* child right */}
        <div className="flex-1 h-screen rounded-md border bg-white overflow-y-auto ml-10">
          {/* <!-- Konten elemen kanan --> */}
        </div>
      </div>
    </div>
    );
}


const IndexPage: Page = () => {
  return (
    <Authorize>
        <InnerIndexPage></InnerIndexPage>
    </Authorize>
  );
};

IndexPage.layout = WithDefaultLayout;
export default IndexPage;
