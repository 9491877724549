import React from "react";

interface ProfileProps {
  name?: string;
  photoUrl: string;
}

const Profile: React.FC<ProfileProps> = ({ name, photoUrl }) => {
  return (
    <div className="flex">
      <img src={photoUrl} alt="Profile Photo"
      className="w-20 h-20 ml-2" />
      <h3 className="flex items-center justify-center ml-3">{name}</h3>
    </div>
  );
};

export default Profile;