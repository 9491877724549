import React, { useState, useEffect } from 'react';

interface SearchBarProps {
  onSearch: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      onSearch(query);
    }, 500); // Menunggu 500ms sebelum melakukan pencarian

    return () => {
      clearTimeout(delayTimer); // Membersihkan timer jika nilai query berubah sebelum pencarian dilakukan
    };
  }, [query, onSearch]);

  return (
    <form>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Search User"
        className="w-full px-4 py-1 h-8 bg-[#F0F5FB]"
      />
    </form>
  );
};

export default SearchBar;